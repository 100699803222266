.animateMoveHorizontal:hover .movingImage {
  animation: move 2s linear infinite;
}
.animateMoveHorizontal:hover .movingImage2 {
  animation: move2 3s linear infinite;
}
@keyframes move {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes move2 {
  0% {
    transform: translate(0px, 0px);
  }
  25% {
    transform: translate(-30px, -30px);
  }
  50% {
    transform: translate(0px, -60px);
  }
  75% {
    transform: translate(30px, -30px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
